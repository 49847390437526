.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
}
.Toastify__toast--success {
    background-color: green;
}
.Toastify__toast--warning {
}
.Toastify__toast--error {
}

.active {
    background-color: #ccc;
} 